@import "variables";

@mixin mobile {
  @media screen and (min-width: 0) and (max-width: 767px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin text12 {
  font-weight: 400;
  font-size: 12px;
  font-family: $fontFamily;
  line-height: 21px;
  color: $black;

  @content;
}

@mixin text14 {
  font-weight: 400;
  font-size: 14px;
  font-family: $fontFamily;
  line-height: 21px;
  color: $black;

  @content;
}

