@import "src/styles/variables";

.client-name-cell {
  padding: 0 !important;

  color: $black;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  font-family: $fontFamily;

  white-space: nowrap;
}
