@import "src/styles/variables";
@import "src/styles/mixins";

.no-results-container {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-top: 8%;

  justify-content: center;
  align-items: center;

  &--icon {
  }

  &--title {
    margin-top: 58px;

    font-size: 20px;
    font-family: $fontFamily;
  }

  &--text {
    margin-top: 20px;

    font-size: 16px;
    font-family: $fontFamily;
    text-align: center;
  }
}
