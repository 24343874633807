@import "src/styles/variables";
@import "src/styles/mixins";

.friends-top-content {
  margin: 0 15px;


  @include mobile {
    margin: -15px 15px 15px 15px;
  }

  .section {
    list-style: none;
    padding: 0;

    .list-item {
      display: flex;
      margin-bottom: 7px;

      .icon {
        margin-right: 15px;
        margin-top: 5px;
      }

      .list-text {
        @include text14;
        @include mobile {
          @include text12;
        }

        .more-details-link {
          @include text14;
          color: $blue;
          
          &:hover {
            cursor: pointer;
          }
          @include mobile {
            @include text12 {
              color: $blue;
            }
          }

        }
      }
    }
  }

  .description {
    margin-top: 6px;

    @include text14 {
      line-height: 18px;
    }

    @include mobile {
      @include text12 {
        line-height: 18px;
      }
    }

  }
}
