@import "src/styles/variables";
@import "src/styles/mixins";

.no-participants-container {
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  &--text {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 143.4%;
    text-align: center;
    color: #000000;

    width: 400px;
    margin-top: 39px;

    @include mobile {
      width: 338px;
      margin-top: 36px;
    }
  }
}
