@import "src/styles/mixins";
@import "src/styles/variables";

.highlighted {
  background-color: $backgroundOrange;
}

.main {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .footer-container {
    margin-top: 40px;
    position: relative;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    height: 100%;

    .footer-container-content {
      height: 60px;
      width: 100%;
      border-top: 0.5px solid #c2d5d5;

      display: flex;
      justify-content: center;
      align-items: center;

      .footer-container-text {
        @include text14 {
          font-weight: 600;
        }

        @include mobile {
          @include text12 {
            font-weight: 600;
          }
        }
      }

      .footer-container-link {
        margin-left: 5px;
        @include text14 {
          font-weight: 600;
        }

        color: $blue;
        text-decoration: underline;

        &:hover {
          cursor: pointer;
        }

        @include mobile {
          @include text12 {
            font-weight: 600;
            color: $blue;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.content {
  display: flex;
  margin: 0 16px;

  @include mobile {
    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  flex-direction: column;

  .select-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;

    .MuiBox-root {
      width: 350px;
    }

    @include mobile {
      justify-content: center;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 5px;

      .MuiBox-root {
        width: 90%;
      }
    }

    .info-icon-container {
    }
  }

  .table-intro {
    align-self: flex-start;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 22px;
  }
}
