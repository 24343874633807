@import "src/styles/mixins";

.friends-tab-container {
  @include desktop {
    width: 810px;
  }

  .container-inner {
    display: flex;
    margin: 0 16px;

    @include mobile {
      margin: 0;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    flex-direction: column;

    .tab-header-container {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      width: 100%;
      margin-bottom: 40px;

      .info-icon-container {
      }
    }

    .table-intro {
      align-self: flex-start;
      margin-bottom: 15px;
      font-weight: bold;
      font-size: 22px;
    }
  }
}
