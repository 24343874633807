@import 'src/styles/mixins';

.navigation {
  display: flex;
  border-bottom: 1px solid #C2D5D5;
  margin-bottom: 20px;

  position: -webkit-sticky;
  position: sticky;
  top: 49px;
  background-color: rgb(246, 248, 249);
  z-index: 1;
  padding-top: 20px;

  @include mobile {
    justify-content: center;
    margin-bottom: 34px;
  }
}
