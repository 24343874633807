@import "src/styles/variables";
@import "src/styles/mixins";

.basic-table-container {
  .highlighted-text {
    color: $green;
    font-size: 14px;

    @include mobile {
      font-size: 12px;
    }
  }

  .normal-text {
    @include text12;
  }

  .grayed-out-text {
    color: $mediumGray;
    font-size: 14px;

    @include mobile {
      font-size: 12px;
    }
  }

  .bold-text {
    font-weight: bold;
    font-size: 14px;

    @include mobile {
      font-size: 12px;
    }
  }
}
