@import "src/styles/mixins";
@import "src/styles/variables";

.challenger-content, .friend-challenge-content {
  @include mobile {
    display: none;
  }
}

.disclaimer-anchor-link {
  color: $blue;

  &:hover {
    cursor: pointer;
  }
}

.dialog-title-container {
  display: flex;
  flex-direction: row;

  .close-icon {
    position: absolute;
    right: 14px;

    &:hover {
      cursor: pointer;
    }
  }
}
