@import "src/styles/variables";

.goals-cell {
  padding: 0 !important;

  .bold-text {
    font-weight: bold;
  }

  .regular-text {
    font-weight: normal;
    color: $mediumGray;
  }
}
