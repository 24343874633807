$black: #000000;
$text: #334245;
$orange: #D6AA1B;
$backgroundOrange: #FBF7E8;

$lightGray: #C2D5D5;
$mediumGray: #879EA2;
$blue: #2EAEFF;
$red: #FDEFF4;
$green: #5BCBB1;

$fontFamily: 'Roboto', serif;
