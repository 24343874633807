@import "src/styles/variables";
@import "src/styles/mixins";

.studio-top-content {
  margin-bottom: 15px;

  @include mobile {
    margin: -15px 15px 15px 15px;
  }

  .section {
    margin-bottom: 17px;
    display: flex;

    .icon {
      margin-right: 15px;
    }
    .text {
      @include text14;

      @include mobile {
        @include text12;
      }
    }
  }

  .description {
    margin-top: 6px;

    @include text14 {
      line-height: 18px;
    }

    @include mobile {
      @include text12 {
        line-height: 18px;
      }
    }


    .more-details-link {
      @include text14 {
        color: $blue;
      }
      &:hover {
        cursor: pointer;
      }
      @include mobile {
        @include text12 {
          color: $blue;
        }
      }
    }
  }
}
