@import "src/styles/mixins";

.studio-tab-container {
  @include desktop {
    width: 810px;
  }
}

.iframe-container {
  width: auto;
}

.iframe-content {
  margin: 0 !important;
}
