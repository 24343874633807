@import "src/styles/mixins";
@import "src/styles/variables";

.locked-from-challenge-container {
  width: auto;

  background-color: $red;
  border-radius: 5px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;

  @include mobile {
    max-width: 350px;
    padding: 10px 0;

    justify-content: center;

    margin-bottom: 15px;
  }

  .icon-container {
    padding: 20px;

    @include mobile {
      padding: 15px;
    }
  }

  .locked-text {
    @include text12 {
      font-weight: 500;
    }

    @include desktop {
      padding-right: 15px;
      white-space: pre-wrap;
    }
  }

}
