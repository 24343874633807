@import "src/styles/variables";
@import "src/styles/mixins";

.table-container {
  .table-cell-header {
    font-size: 12px;
    line-height: 14px;
    color: $black;
    font-weight: 600;
    font-family: "Roboto", serif;

    padding: 7px !important;
  }

  .is-completed-cell {
    padding: 0 !important;
  }

  .highlighted-text {
    color: $blue;
  }

  .MutTableCell-root {
    padding: 10px !important;
  }

  .normal-text {
    @include text12;
  }

  .grayed-out-text {
    color: $mediumGray;
  }

  .bold-text {
    font-weight: bold;
  }
}
