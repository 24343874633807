@import "src/styles/variables";
@import "src/styles/mixins";
@mixin hover-cursor {
  &:hover {
    cursor: pointer;
  }
}

.navigation-tab {
  padding-bottom: 20px;
  @include hover-cursor;

  &--active {
    border-bottom: 3px solid #5bcbb1;
    @include hover-cursor;
  }
}

.navigation-title {
  padding: 0 30px;
  font-weight: bold;
  font-size: 14px;
  color: $text;
  font-family: "Roboto", serif;

  @include mobile {
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .navigation-tab {
    padding-bottom: 10px;
  }

  .navigation-title {
    padding: 0 15px;

    @include mobile {
      padding: 0 8px;
    }
  }
}
