@import "src/styles/variables";

.participants-cell {
  padding: 7px 0 !important;

  &--inner {
    display: flex;
    align-items: center;

    padding: 5px 0;

    .rank-container {
      margin-left: 16px;
      font-family: $fontFamily;
      font-weight: 700;
      font-size: 14px;
    }

    .avatar-container {
      margin: 0 10px;
    }

    .client-name-container {
      margin-left: 10px;
    }
  }
}
