@import "src/styles/variables";

.avatar-container {

  .connected-highlight {
    width: 40px;
    border: 2px solid $orange;
    border-radius: 50px;
    height: 40px;
  }

  .current-user-highlight {
    width: 40px;
    border: 2px solid $green;
    border-radius: 50px;
    height: 40px;
  }

  .no-highlight {
    border: 0.1px solid #EFEFEF;
    width: 40px;
    border-radius: 50px;
    height: 40px;
  }

  .icon-container {
    z-index: 2;
    position: absolute;
  }
}
